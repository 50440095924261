import { WorkoutContextType } from './types';

export const DEFAULT_VOLUME = 0.4;
export const VOLUME_KEY_IN_LS = 'workout-volume';

export const EXERCISE_AUDIO_CONFIG = {
    3: 'finish',
    6: 'preEnd',
};

export const LAUNCH_AUDIO_CONFIG = {
    1: 'start',
};

export const DEFAULT_SPEND_TIME = 100;

const defaultExercise = {
    id: 0,
    media_url: '',
    web_media_url: '',
    preview: '',
    base_pace: 0,
    base_mets: 0,
    updated_at: '',
    audio_url: '',
    name: '',
};

const defaultBlockExercise = {
    id: 0,
    time: 0,
    repetitions: null,
    target_area: '',
    type: 0,
};

export const DEFAULT_CONTEXT_VALUES: WorkoutContextType = {
    handleNavigateExercise:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (moveFunction: () => void, trackFunction: (id: number, position: number) => void) => () => {},
    fancyProgressTime: '',
    fullWorkoutTime: '',
    completeData: null,
    isComplete: false,
    handleExerciseClick: console.log,
    toNextExercise: () => {},
    toPrevExercise: () => {},
    handleProgress: console.log,
    prevExercise: defaultBlockExercise,
    currentExercise: defaultBlockExercise,
    exerciseData: defaultExercise,
    nextExerciseData: defaultExercise,
    currentIndex: 0,
    currentPlaylistPosition: 0,
    progressStore: {},
    playerContainer: {
        current: null,
    },
    isPageBlocked: false,
};
