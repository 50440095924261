import ReactPlayer from 'react-player/file';
import React from 'react';

import { PlayerWrapper } from './styled';

export const PlayerComponent = ({ playing, url, onReady }: { playing: boolean; url: string; onReady: () => void }) => {
    return (
        <PlayerWrapper>
            <ReactPlayer
                url={url}
                playing={playing}
                controls={false}
                playsinline={true}
                loop={true}
                muted={true}
                width="100%"
                height="100%"
                config={{
                    attributes: {
                        onCanPlayThrough: () => {
                            onReady();
                        },
                        type: 'video/mp4',
                        forceAudio: false,
                    },
                }}
            />
        </PlayerWrapper>
    );
};

export default PlayerComponent;
